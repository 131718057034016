setTimeout(function(){

  $('.contact_click').click(()=>{
    $('html, body').animate({
      scrollTop: $('#formDiv').offset().top - 50
    }, 0);
  })

  //GET HEIGHT HEADER FOR AUTO SEPARATOR
  const header = document.getElementById('header'),
  sepHeader = document.getElementById('separatorHeader')
  if (header) {
    let heightHeader = header.offsetHeight
    sepHeader.style.height = heightHeader.toString() + 'px'
  }


  ///////OPEN ACCORDION////////
  let acc = document.getElementsByClassName('accTittle'),
  content = document.getElementsByClassName('accContent')

  if (acc) {
    for (i = 0; i < acc.length; i++) {
      acc[i].addEventListener('click', function () {
        this.classList.toggle('rotated')
        let content = this.nextElementSibling
        content.classList.toggle('openedContent')
      })
    }
  }
  
},1000)