import React from 'react'

let vector = [0]

const Academy = function academy ({video,academy_options,academy_options_list}){
    return (
        <section className="academy">
            <div className="container colflex">

                <h1>{video.title}</h1>
                <div className="video flex">
                    <iframe src={"https://www.youtube.com/embed/"+video.video_id} frameBorder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope" allowFullScreen></iframe>
                </div>

                <h2>{video.subtitle}</h2>
                <p dangerouslySetInnerHTML={{__html: video.text}}></p>

                <div className="faqs colflex">

                    {academy_options.map((ao)=>{
                        let list = academy_options_list.filter(
                            (a) => a.academy_options_id === ao.id,
                        );
                
                        return(
                            <div className="option colflex" key={ao.id}>
                                <div className="title flex relative">
                                    <h3>{ao.name}</h3>
                                </div>

                                {list.map((l)=>{
                                    return(
                                        <div key={l.id} className='div'>
                                            <div className="accTittle flex">
                                                <div className="content colflex">
                                                    <h4>{l.title}</h4>
                                                    <p>{l.subtitle}</p>
                                                </div>
                                                <span className="add">+</span>
                                                <span className="minus">-</span>
                                            </div>
                                            <div className="accContent">
                                                <p dangerouslySetInnerHTML={{__html: l.text}}></p>
                                            </div>
                                        </div>
                                    )
                                })}

                            </div>
                        )
                    })}

                </div>

                <a className='contact_click'>Unete</a>

            </div>
        </section>
    )
}

export default Academy