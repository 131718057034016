import React from 'react'

let vector = [0]
let validate = 1

const Banner = function banner ({list}){
    return (
        <section className="bannerHome">
            <div className="content" id="bannerHome">
                {list.map((l)=>{
                    return(
                        <a href={l.url} className="flex" key={l.id}>
                            <picture className="flex">
                                <source media="(min-width:768px)" srcSet={l.img}/>
                                <img src={l.img_mobile}/>
                            </picture>
                        </a>
                    )
                })}
            </div>
            {vector.map((v)=>{
                if(list.length > 0 && validate == 1){
                    validate = 2
                    setTimeout(function(){
                        $('#bannerHome').slick({
                            slidesToShow: 1,
                            dots: false,
                            arrows: false,
                            autoplay: true,
                            autoplaySpeed: 4000,
                          });
                    },100)
                }
            })}
        </section>
    )
}

export default Banner