import React from 'react'

let vector = [0]

const Goto = function goto ({text}){
    return (
        <section className="goto">
            <div className="container colflex">
                
                <div className="coma relative">
                    <img src="/img/coma.svg" alt="coma" className="mark absolute"/>
                    <p>{text.title}”.</p>
                </div>

                <div className="advance colflex">
                    <h2 dangerouslySetInnerHTML={{__html: text.subtitle}}></h2>
                    <div className="text colflex">
                        <p dangerouslySetInnerHTML={{__html: text.text}}></p>
                        <a className='contact_click'>Unete</a>
                    </div>
                </div>

                <div className="lists colflex">

                    <div className="item colflex">
                        <div className="title flex">
                            <img src="/img/icons/title.svg" alt="title-icon"/>
                            <p>Descubre tus <span>pasiones, dones y talentos</span></p>
                        </div>
                        <div className="list colflex">
                            <div className="flex">
                                <img src="/img/icons/idea.svg" alt="icon-list"/>
                                <div className="text colflex">
                                    <h4>Genera ideas de negocios</h4>
                                    <p>con base a lo que eres.</p>
                                </div>
                            </div>
                            <div className="flex">
                                <img src="img/icons/time.svg" alt="icon-list"/>
                                <div className="text colflex">
                                    <h4>Valida tus ideas en poco tiempo</h4>
                                    <p>al menor costo.</p>
                                </div>
                            </div>
                            <div className="flex">
                                <img src="/img/icons/implement.svg" alt="icon-list"/>
                                <div className="text colflex">
                                    <h4>Implementa <span>tus ideas.</span></h4>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="item colflex">
                        <div className="title flex">
                            <img src="/img/icons/title.svg" alt="title-icon"/>
                            <p><span>Aprende Marketing Digital</span> bajo una metodología practica y muy sencilla</p>
                        </div>
                        <div className="list colflex">
                            <div className="flex">
                                <img src="/img/icons/network.svg" alt="icon-list"/>
                                <div className="text colflex">
                                    <h4>Participa en networking</h4>
                                    <p>Durante todo el curso que te ayudaran a generar contactos y muchos negocios.</p>
                                </div>
                            </div>
                            <div className="flex">
                                <img src="/img/icons/resource.svg" alt="icon-list"/>
                                <div className="text colflex">
                                    <h4>Aprende a busca recursos</h4>
                                    <p>de apalancamiento o financiación.</p>
                                </div>
                            </div>
                            <div className="flex">
                                <img src="/img/icons/scholar.svg" alt="icon-list"/>
                                <div className="text colflex">
                                    <h4>Gana becas <span>o bonos de avanzada.</span></h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </section>
    )
}

export default Goto