import React from 'react'

let vector = [0]

const Footer = function footer ({info}){
    return (
        <div>
            <a href={info.whatsapp} target='_blank' className="floatWpp grid flex">
                <img src="img/float2.svg" alt="🌐"/>
            </a>
            <footer>
                <div className="container colflex">
                    <img src="/img/logow.svg" alt="logo-footer" className="logoFoo"/>
                    <div className="item colflex">
                        <img src="/img/icons/call.svg" alt="☎️"/>
                        <h3>llámanos</h3>
                        <p>{info.phone}</p>
                    </div>
                    <div className="item colflex">
                        <img src="/img/icons/marker.svg" alt="📍"/>
                        <h3>encuéntranos</h3>
                        <p>{info.address}</p>
                    </div>
                    <div className="item colflex">
                        <img src="/img/icons/envelope.svg" alt="📧"/>
                        <h3>Escríbenos</h3>
                        <p>{info.mail}</p>
                    </div>
                    <div className="item colflex">
                        <h3>Síguenos</h3>
                        <div className="social flex">
                            <a href={info.facebook} target='_blank' className="flex">
                                <img src="/img/icons/fb.svg" alt="Facebook"/>
                            </a>
                            <a href={info.instagram} target='_blank' className="flex">
                                <img src="/img/icons/insta.svg" alt="Instagram"/>
                            </a>
                            <a href={info.youtube} target='_blank' className="flex">
                                <img src="/img/icons/youtube.svg" alt="Youtube"/>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="gtnd colflex">
                    <p>Emprendiendo con Pasión.</p>
                    <p>Todos los derechos reservados</p>
                    <p className="marker">|</p>
                    <p>Creado por</p>
                    <a href="https://gutand.com/" target='_blank' className="flex">
                        <img src="/img/icons/gtnd.svg" alt="gutand-icon"/>
                    </a>
                </div>
            </footer>
        </div>
    )
}

export default Footer