import React from 'react'

let vector = [0]

const Discover = function discover ({text}){
    return (
        <section className="discover">
            <div className="container colflex">
                <div className="text colflex">
                    <h1>{text.title}</h1>
                    <h2>{text.subtitle}</h2>
                    <p dangerouslySetInnerHTML={{__html: text.text}}></p>
                </div>
                <img src={text.image} alt=""/>
            </div>
        </section>
    )
}

export default Discover