import React from 'react'

let vector = [0]

const Offer = function Offer ({text,receive}){
    return (
        <section className="offer">
            <div className="container colflex">
                <div className="items colflex">
                    <div className="pt1 colflex">
                        <div className="text colflex">
                            <h2>Oferta hoy</h2>
                            <h1>${text.title}</h1>
                            <h3>Precio real:</h3>
                            <h3>${text.subtitle}</h3>
                            <p dangerouslySetInnerHTML={{__html: text.text}}></p>
                        </div>
                        <a className="flex contact_click">
                            <span>¡Quiero esta oferta!</span>
                            <img src="/img/arrowb.svg" alt="➡️"/>
                        </a>
                    </div>

                    <div className="pt2 colflex">
                        <h1>¿Qué recibes?</h1>
                        <ul className="list colflex" style={{listStyleImage: "url('/img/ticks.svg')"}}
                        >
                            {receive.map((r)=>{
                                return(
                                    <li key={r.id}>{r.name}</li>
                                )
                            })}
                        </ul>
                    </div>
                </div>
                <img src="/img/offer2.png" alt="ofer-img"/>
            </div>
        </section>
    )
}

export default Offer