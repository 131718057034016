import React,{useRef,Component} from 'react';
import { createRoot } from "react-dom/client";

import Header from "../components/header";
import Footer from "../components/footer";

import Banner from "../components/index/banner";
import Proved from "../components/index/proved";
import Video from "../components/index/video";
import Form from "../components/index/form";
import Discover from "../components/index/discover";
import Goto from "../components/index/goto";
import Offer from "../components/index/offer";
import Academy from "../components/index/academy";

const rootElement = document.getElementById("content_index");
const root = createRoot(rootElement);

export default class Index extends Component {

    constructor(props){
        super(props)

        this.state = {
            name: 'index',
            info:[],
            banner:[],
            text:[],
            text_1:[],
            text_2:[],
            text_3:[],
            text_4:[],
            video_1:[],
            video_2:[],
            academy_options:[],
            academy_options_list:[],
            error: null,
            form:{
                name: '',
                phone: '',
                mail: '',
                city: '',
                messague: '',
                token:'',
            },
            receive: []
        }

        this.handleChangeForm = this.handleChangeForm.bind(this)
        this.formSubmit = this.formSubmit.bind(this)
        this.submitClick = this.submitClick.bind(this)

        this._recaptchaRef = React.createRef()

    }


    handleChangeForm(e){
        this.setState({
            form:{
                ...this.state.form,
                [e.target.name]:e.target.value
            }
        })
    }

    formSubmit = async (e) => {
        if(
            this.state.form.name == '' ||
            this.state.form.phone == '' ||
            this.state.form.mail == '' ||
            this.state.form.city == '' 
        ){
            swal({
                text: "Debes digitar los datos necesarios",
                icon: imageURL,
                button: "OK!",
            });
            return false;
        }

        swal({
            text: "Espere un momento",
            icon: imageURL,
            button: false,
            closeOnClickOutside: false,
        });

        if(this.state.form.token == ""){
            const token = await this._recaptchaRef.current.executeAsync();
            this.setState({
                form:{
                    ...this.state.form,
                    token: token
                }
            });
            if(!token){
                swal({
                    text: "Error, eres un robot",
                    icon: "error",
                    button: "OK!",
                    closeOnClickOutside: false,
                });
                return false;
            }
        }

        try{
            let config = {
                method: 'POST',
                headers:{
                    'Accept':'application/json',
                    'Content-Type':'application/json'
                },
                body: JSON.stringify(this.state.form)
            }
            let res = await fetch(`/api/contact`,config)
            let data = await res.json()
            if(data == 1){
                this.setState({
                    form:{
                        name: '',
                        phone: '',
                        mail: '',
                        city: '',
                        messague: '',
                        token:'',
                    }
                })
                document.getElementById("formContact").reset();
                swal.close();
                location.href = "/gracias"
            }else{
                alert('ocurrio un error');
                setTimeout(function (){
                    //location.reload();
                },1000)
            }
        }catch (error) {
            this.setState({
                error
            })
        }
    }

    submitClick(){
        this.formSubmit()
    }

    async componentDidMount(){
        try{
            let res = await fetch('/get/home')
            let data = await res.json()
            this.setState({
                info:data[0],
                banner:data[1],
                text:data[2],
                text_1:data[2][0],
                text_2:data[2][1],
                text_3:data[2][2],
                text_4:data[2][3],
                video_1:data[3][0],
                video_2:data[3][1],
                academy_options:data[4],
                academy_options_list:data[5],
                receive: data[6]
            })
        }catch (error) {
            this.setState({
                error
            })
        }
    }

    render() {
        return (
            <div>
                <Header
                />
                <main>
                    <Banner
                        list={this.state.banner}
                    />
                    <Proved/>
                    <Video
                        list={this.state.video_1}
                    />
                    <Form
                        text={this.state.text_1}
                        
                        onChange={this.handleChangeForm}
                        form={this.state.form}
                        submit={this.submitClick}
                        recaptchaRef={this._recaptchaRef}
                    />
                    <Discover
                        text={this.state.text_2}
                    />
                    <Goto
                        text={this.state.text_3}
                    />
                    <Offer
                        text={this.state.text_4}
                        receive={this.state.receive}
                    />
                    <Academy
                        video={this.state.video_2}
                        academy_options={this.state.academy_options}
                        academy_options_list={this.state.academy_options_list}
                    />
                </main>
                <Footer
                    info={this.state.info}
                />
            </div>
        );
    }

}

if (document.getElementById('content_index')) {
    root.render(
        <Index />
    )
}