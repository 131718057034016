import React from 'react'

let vector = [0]

const Video = function video ({list}){
    return (
        <section className="video">
            <div className="container colflex">
                <div className="text colflex">
                    <p>{list.title}</p>
                    <h2>{list.subtitle}</h2>
                    <a className='contact_click'>Unete</a>
                </div>
                <div className="youtubeVideo flex">
                    <iframe src={"https://www.youtube.com/embed/"+list.video_id} frameBorder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope" allowFullScreen></iframe>
                </div>
            </div>
        </section>
    )
}

export default Video