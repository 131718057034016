import React,{useRef,Component} from 'react';
import { createRoot } from "react-dom/client";

import Header from "../components/header";
import Footer from "../components/footer";

import Content from "../components/thanks/content";

const rootElement = document.getElementById("content_thanks");
const root = createRoot(rootElement);

export default class Thanks extends Component {

    constructor(props){
        super(props)

        this.state = {
            name: 'thanks',
            info:[],
            error: null,
        }

    }

    async componentDidMount(){
        try{
            let res = await fetch('/get/home')
            let data = await res.json()
            this.setState({
                info:data[0],
            })
        }catch (error) {
            this.setState({
                error
            })
        }
    }

    render() {
        return (
            <div>
                <Header
                />
                <main>
                    <Content
                    />
                </main>
                <Footer
                    info={this.state.info}
                />
            </div>
        );
    }

}

if (document.getElementById('content_thanks')) {
    root.render(
        <Thanks />
    )
}