import React from 'react'

let vector = [0]

const Content = function content ({}){
    return (
        <div className='thanks'>
            <img src="/img/logo.svg" />
            <h1>
            <span>Gracias por contactarnos,</span> nos comunicaremos muy pronto con usted
            </h1>
        </div>
    )
}

export default Content